







































































































































































































import editMixin from "@/mixin/edit-mixin";
export default {
  mixins: [editMixin],
  data() {
    return {};
  },
};
